const { i18n } = require('../components/basics/shared/site');

// graphql function doesn't throw an error so we have to check to check for the result.errors to throw manually
exports.wrapper = promise =>
  promise.then(result => {
    if (result.errors) {
      throw result.errors;
    }
    return result;
  });

// Remove trailing slashes unless it's only "/", then leave it as it is
exports.replaceTrailing = path => (path === `/` ? path : path.replace(/\/$/, ``));

// Remove slashes at the beginning and end
exports.replaceBoth = _path => _path.replace(/^\/|\/$/g, '');

// If the "lang" is the default language, don't create a prefix. Otherwise add a "/$path" before the slug (defined in "i18n")
exports.localizedSlug = node =>
  i18n[node.lang].default ? `/${node.uid}` : `/${i18n[node.lang].path}/${node.uid}`;
