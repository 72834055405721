/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { elastic as ElasticMenu } from 'react-burger-menu';

import { Link, Icon, TooltipLinkList, WithTooltip, styles, site } from '../basics';
// import { navLinks } from './PageLayout';

const { color, typography, spacing, pageMargins, breakpoint } = styles;
const { metadata, url } = site;

const LogotypeWrapper = styled(Link)`
  display: inline-block;
  img {
    height: 30px;
    width: auto;
    margin-top: 14px;
    @media (min-width: ${breakpoint}px) {
      height: 38px;
      margin-top: 10px;
    }

    display: block;

    transition: all 150ms ease-out;
    transform: translate3d(0, 0, 0);
    &:hover {
      transform: translate3d(0, -1px, 0);
    }
    &:active {
      transform: translate3d(0, 0, 0);
    }
  }
`;

// const Version = styled(Link)`
//   display: inline-block;
//   vertical-align: top;
//   margin-left: 10px;
//   position: relative;
//   top: 2px;
//   font-size: ${typography.size.s1}px;
//   color: ${color.mediumdark};
// `;

const TooltipLinkListWrapper = styled.div`
  padding: 8px 5px;
`;

const NavLink = styled(Link)`
  font-size: ${typography.size.s3}px;
  font-weight: ${typography.weight.bold};
  color: #3d4465;
  :hover {
    color: #323648;
  }
`;

const Menu = styled(Link)`
  width: 3rem;
  height: 3rem;
  border: none !important;
  text-decoration: none !important;
  background: none !important;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    vertical-align: top;
    height: 1.5rem;
    width: 1.5rem;
    padding: 2px !important;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
  }
`;

const MobileMenu = styled.div`
  font-size: ${typography.size.s1}px;

  ${TooltipLinkListWrapper} {
    padding: 5px 0;
  }
`;

const NavItem = styled.div`
  display: flex;
  line-height: 3rem;
  height: 3rem;
  vertical-align: top;

  ${props =>
    props.showDesktop &&
    css`
      display: none;
      @media (min-width: ${breakpoint * 1.333}px) {
        display: flex;
      }
    `};

  ${props =>
    props.showMobile &&
    css`
      @media (min-width: ${breakpoint * 1.333}px) {
        display: none;
      }
    `};
`;

const NavGroup = styled.div`
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;

  ${props =>
    props.right &&
    css`
      left: auto;
      right: 0;
    `}

  ${NavItem} + ${NavItem} {
    margin-left: ${spacing.padding.large}px;
  }
`;

const Nav = styled.div`
  ${pageMargins};
  height: 3rem;
  position: relative;
  text-align: center;
  z-index: 3;
  display: flex;
  justify-content: space-between;
`;

const NavWrapper = styled.nav`
  /* ${pageMargins}; */
  padding-top: 12px;
  @media (min-width: ${breakpoint}px) {
    padding-top: 12px;
  }

  background-image: none;
  /* box-sizing: border-box; */
  /* display: block; */
  left: 0;
  padding: 10px 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999;
  /* display: flex; */
`;

export default function Header({ header, pageContext, ...props }) {
  const navLinks = [];
  if (header && header.items) {
    header.items.forEach(item => {
      navLinks.push({
        title: item.text,
        href: item.url.url,
        isGatsby: false,
      });
    });
  } else {
    [
      { title: 'Cadastrar', href: url.webapp.signup, isGatsby: true },
      { title: 'Entrar', href: url.webapp.signin, isGatsby: true },
    ].forEach(item => navLinks.push(item));
  }

  const mobileMenu = (
    <MobileMenu>
      <TooltipLinkList
        links={navLinks}
        // TODO: Pass GatsbyLink here
        LinkWrapper={GatsbyLink}
      />
    </MobileMenu>
  );

  return (
    <NavWrapper {...props}>
      <Nav>
        <NavGroup>
          <NavItem>
            <LogotypeWrapper isGatsby to={pageContext.localizedPath}>
              <img
                src="https://public.rocketon.app/landings/images/rocketon-logo.svg"
                alt="RocketOn"
              />
            </LogotypeWrapper>
          </NavItem>
        </NavGroup>

        <NavGroup right>
          {navLinks.map(({ title, href, isGatsby }) => (
            <NavItem showDesktop key={title}>
              <NavLink
                tertiary={1}
                href={!isGatsby ? href : undefined}
                to={isGatsby ? href : undefined}
                isGatsby={isGatsby}
              >
                {title}
              </NavLink>
            </NavItem>
          ))}
          <NavItem showMobile>
            <WithTooltip placement="top" trigger="click" tooltip={mobileMenu}>
              <Menu aria-label="Menu" tertiary icon={1} isButton>
                <Icon icon="menu" />
              </Menu>
            </WithTooltip>
          </NavItem>
        </NavGroup>
      </Nav>
    </NavWrapper>
  );
}

Header.propTypes = {
  header: PropTypes.object.isRequired,
};
