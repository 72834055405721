/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import { SocialGraph, site } from '../basics';
import Header from './Header';
import Footer from './Footer';

const { metadata, url, i18n } = site;

const Layout = styled.div``;

export const navLinks = [
  { title: 'Helpdesk', href: url.webapp.signin, isGatsby: true },
  { title: 'Cadastrar', href: url.webapp.signup, isGatsby: true },
  { title: 'Entrar', href: url.webapp.signin, isGatsby: true },
];

export default function PageLayout({
  children,
  header,
  pageIndex,
  countries,
  links,
  iubenda,
  pageContext,
  pathname,
  ...props
}) {
  return (
    <Layout {...props}>
      <SocialGraph
        // this is default social graph styling, it will be overridden by page-specific oggraph
        title={pageIndex.title}
        desc={pageIndex.description}
        url={`${pageContext.url}${pageContext.localizedPath}`}
        image={pageIndex.ogImage}
      />
      <Helmet
        // meta={[
        //   {
        //     name: 'keywords',
        //     content:
        //       'UI, components, design systems, library, styleguide, catalog, React, Vue, Angular',
        //   },
        // ]}
        defer={false}
      >
        <html
          xmlns="http://www.w3.org/1999/xhtml"
          xmlLang={i18n[pageContext.locale].siteLanguage}
          lang={i18n[pageContext.locale].siteLanguage}
          prefix="og: http://ogp.me/ns#"
        />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          rel="dns-prefetch"
          key="dns-prefetch-fonts-googleapis"
          href="https://fonts.googleapis.com"
          crossOrigin
        />
        <link
          rel="dns-prefetch"
          key="dns-prefetch-fonts-googleapis"
          href="https://fonts.gstatic.com"
          crossOrigin
        />
        <link rel="canonical" href={`${pageContext.url}${pageContext.localizedPath}`} />
        <link rel="alternate" href={`${pageContext.url}${pageContext.xpath}`} hrefLang="en" />
        <link
          rel="alternate"
          href={`${pageContext.url}${pageContext.xpath}`}
          hrefLang="x-default"
        />
        <link rel="alternate" href={`${pageContext.url}/br${pageContext.xpath}`} hrefLang="pt-BR" />
        {/* <link
          rel="shortcut icon"
          type="image/png"
          href="/images/logos/icon-storybook.png"
          sizes="16x16 32x32 64x64"
        /> */}
        <link rel="prerender" href="https://qa-web.rocketon.app/auth/signup" crossOrigin />
        <link rel="prerender" href="https://qa-web.rocketon.app/auth/signin" crossOrigin />
        <script type="text/javascript">
          {`var _iub = _iub || [];
          _iub.csConfiguration = {"lang":"${iubenda.language}","siteId":${
            iubenda.site_id
          },"cookiePolicyId":${
            iubenda.policy_id
          }, "banner":{ "position":"bottom","textColor":"black","backgroundColor":"white" } };
          `}
        </script>
        <script
          type="text/javascript"
          src="https://cdn.iubenda.com/cs/iubenda_cs.js"
          charset="UTF-8"
          async
        />
        <script type="text/javascript">
          {`(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);`}
        </script>
        <script type="text/javascript">{`window.$crisp=[];window.CRISP_WEBSITE_ID="d6aabb71-1585-4b37-bf27-5a8a1276fbec";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`}</script>
        <script>
          {`
            (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:1239251,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `}
        </script>
      </Helmet>
      <Header header={header} pageContext={pageContext} />
      {children}
      <Footer countries={countries} links={links} iubenda={iubenda} />
      {/* <footer>
        © 2018-2019 RocketOn® é uma marca registrada de RocketOn Serviços Digitais Ltda | CNPJ:
        12.381.965/0001-50.
        <br />
        <a
          href="https://www.iubenda.com/privacy-policy/83346698"
          className="iubenda-black no-brand iubenda-embed"
          title="Privacy Policy "
        >
          Privacy Policy
        </a>
        <a
          href="https://www.iubenda.com/privacy-policy/83346698/cookie-policy"
          className="iubenda-black no-brand iubenda-embed"
          title="Cookie Policy "
        >
          Cookie Policy
        </a>
      </footer> */}
    </Layout>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  pageIndex: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
};
