import React, { Fragment } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import Img from 'gatsby-image';
import { Badge, Button, Link, SocialGraph, styles, site, Video } from '../../basics';
import PageLayout from '../../layout/PageLayout';
import Hero from './Hero';
import SocialProof from '../../layout/SocialProof';
import PlaceholderAspectRatio from '../../layout/PlaceholderAspectRatio';
import ValueProp from '../../layout/ValueProp';
import BenefitItem from './BenefitItem';
import BenefitList from './BenefitList';
import CTA from '../../layout/CTA';

const { breakpoint, pageMargins, background } = styles;
const { metadata, url } = site;

const Contrast = styled.div`
  background-color: ${background.app};
`;

const Separator = styled.hr`
  margin: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
`;

const Placeholder = styled(PlaceholderAspectRatio)`
  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

const HeroBkg = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  background-image: url(https://public.rocketon.app/landings/images/rocketon-coach-hero.jpg);
  background-blend-mode: overlay;
  background-size: cover;
  box-sizing: border-box;
  display: block;
  /* min-height: calc(100vh - 7rem); */
  min-height: calc(100vh);
  /* @media (min-width: ${breakpoint * 2}px) {

  } */
  @media (min-device-width: 320px) and (max-device-width: 900px) and (orientation: landscape) {
    min-height: calc(100vh);
  }
  /* @media (max-width: ${breakpoint * 1.333}px) and (orientation: portrait) {
    min-height: calc(80vh);
  } */
  /* @media (max-width: ${breakpoint * 1.333}px) and (orientation: landscape) {
    min-height: calc(100vh);
  } */
  background-position: center;
  /* background-size: auto; */
  /* padding: 100px 0; */
  position: relative;
  z-index: 1;
`;

export function PureIndexScreen({
  data: { heroImage },
  pageIndex,
  pageContext,
  iubenda,
  links,
  countries,
  pathname,
  ...props
}) {
  console.log(heroImage);
  return (
    <PageLayout
      pageIndex={pageIndex}
      countries={countries}
      iubenda={iubenda}
      links={links}
      pageContext={pageContext}
      pathname={pathname}
      {...props}
    >
      <SocialGraph
        title={pageIndex.og_title}
        desc={pageIndex.og_description}
        url={url.home}
        image={metadata.ogImage}
      />
      {/* <HeroBkg> */}
      <Hero
        title={pageIndex.hero_title}
        subtitle={pageIndex.hero_subtitle}
        heroImage={heroImage}
        signup={{ text: pageIndex.sign_up_button_text, link: pageIndex.sign_up_button_link }}
        signin={{ text: pageIndex.sign_in_button_text, link: pageIndex.sign_in_button_link }}
      />
      {/* </HeroBkg> */}
      {/* <Img fluid={heroImage.childImageSharp.fluid} alt="Hero Image" /> */}
      <Separator />

      {/* <Separator /> */}

      {pageIndex.body.map((feature, featureIndex) => {
        if (feature.slice_type !== 'major_feature') return '';
        if (feature.primary.visible === 'false') return '';

        const subFeatures = feature.items.map(subFeature => (
          <BenefitItem
            key={`sub-feature-${feature.id}-${subFeature.sub_feature_title}`}
            image={
              <Img
                fluid={subFeature.sub_feature_img.localFile.childImageSharp.fluid}
                alt={subFeature.sub_feature_title}
              />
            }
            title={subFeature.sub_feature_title}
            desc={subFeature.sub_feature_subtitle}
          />
        ));
        return (
          <Fragment key={`major-feature-${feature.id}`}>
            <Contrast style={{ backgroundColor: '#212121' }}>
              <ValueProp
                orientation={feature.primary.video_position.toLowerCase()}
                media={
                  /* eslint-disable jsx-a11y/media-has-caption */
                  <Placeholder ratio={0.63}>
                    <Video
                      src={feature.primary.feature_video.url}
                      alt="Video demo"
                      shouldChangeSize
                    />
                  </Placeholder>
                }
                title={feature.primary.feature_title}
                desc={feature.primary.feature_subtitle}
                lazyloadPlaceholder={<Placeholder ratio={0.55} />}
              />
              <Separator />
            </Contrast>
            {feature.items.length > 0 ? <BenefitList>{subFeatures}</BenefitList> : ''}
            {/* <Separator /> */}
          </Fragment>
        );
      })}
      <Contrast style={{ backgroundColor: '#fecd52', borderColor: 'none' }}>
        <SocialProof
          style={{ color: '#475075 !important' }}
          heading={pageIndex.hero_tagline}
          path="images/logos/user"
          brands={[]}
          monochrome
        />
      </Contrast>
      <CTA
        text={<span>{pageIndex.bottom_action_text}</span>}
        action={
          <Button primary isLink href={pageIndex.bottom_action_button_link}>
            {pageIndex.bottom_action_button_text}
          </Button>
        }
        style={{ backgroundColor: '#F4F6F8' }}
      />
    </PageLayout>
  );
}

export default function IndexScreen(props) {
  return (
    <StaticQuery
      query={graphql`
        query IndexScreenQuery {
          heroImage: file(relativePath: { eq: "hero-illustration.png" }) {
            ...fluidImage
          }
        }
      `}
      render={data => <PureIndexScreen data={data} {...props} />}
    />
  );
}

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 600) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;
