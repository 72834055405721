import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Icon, Link, Subheading, styles, site } from '../basics';

import ConfirmedMailingList from './ConfirmedMailingList';
import { navLinks } from './PageLayout';

const { background, color, typography, pageMargins, pageMargin, spacing, breakpoint } = styles;
const { url } = site;

const Title = styled(Subheading)`
  display: block;
  font-size: ${typography.size.s1}px;
  margin-bottom: 1rem;
  color: ${color.mediumdark};
`;

const SubLink = styled(Link)`
  text-transform: none;
  letter-spacing: 0;
  font-weight: ${typography.weight.regular};
  margin-left: 20px;
`;

const ResourceTitle = styled.div`
  font-weight: ${typography.weight.black};
  margin-bottom: 0.25rem;
`;

const ResourceTitleLink = styled(Link)`
  font-weight: ${typography.weight.black};
  margin-bottom: 0.25rem;

  svg {
    height: 0.7rem;
    width: 0.7rem;
    vertical-align: initial;
  }
`;

const ResourceDesc = styled.div`
  margin-bottom: 0.25rem;
`;

const ResourceAction = styled(Link)`
  margin-right: 15px;
`;

const ResourceActions = styled.div``;

const Meta = styled.div`
  overflow: hidden;
`;

const Resource = styled.div`
  display: flex;
  align-items: start;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  img {
    margin-right: 20px;
    display: block;
    width: 40px;
    height: auto;
  }

  @media (min-width: ${breakpoint * 1}px) {
    img {
      width: 48px;
    }
  }
`;

const Resources = styled.div``;

const UpperColumn = styled.div`
  flex: 1;

  padding-left: ${spacing.padding.medium}px;
  padding-right: ${spacing.padding.medium}px;
  padding-top: 3rem;
  padding-bottom: 3rem;

  &:last-child {
    border-top: 1px solid ${color.border};
  }

  @media (min-width: ${breakpoint * 1}px) {
    &:first-child {
      margin-left: ${pageMargin * 1}%;
      padding-right: 60px;
    }
    &:last-child {
      margin-right: ${pageMargin * 1}%;
      padding-left: 60px;
      border-top: none;
      border-left: 1px solid ${color.border};
    }
  }

  @media (min-width: ${breakpoint * 2}px) {
    &:first-child {
      margin-left: ${pageMargin * 2}%;
    }
    &:last-child {
      margin-right: ${pageMargin * 2}%;
    }
  }

  @media (min-width: ${breakpoint * 3}px) {
    &:first-child {
      margin-left: ${pageMargin * 3}%;
    }
    &:last-child {
      margin-right: ${pageMargin * 3}%;
    }
  }

  @media (min-width: ${breakpoint * 4}px) {
    &:first-child {
      margin-left: ${pageMargin * 4}%;
    }
    &:last-child {
      margin-right: ${pageMargin * 4}%;
    }
  }
`;

const Upper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  border-bottom: 1px solid ${color.border};

  @media (min-width: ${breakpoint}px) {
    flex-direction: row;
  }
`;

const LogotypeWrapper = styled(Link)`
  margin-bottom: 1rem;
  display: block;

  img {
    height: 28px;
    width: auto;
    display: block;

    transition: all 150ms ease-out;
    transform: translate3d(0, 0, 0);
    &:hover {
      transform: translate3d(0, -1px, 0);
    }
    &:active {
      transform: translate3d(0, 0, 0);
    }
  }
`;

const FooterLink = styled(Link)`
  text-decoration: none;
`;

const Text = styled.div`
  color: ${color.darker};
`;
const Colophon = styled.div`
  a {
    display: inline-block;
    vertical-align: top;
  }
`;

const Column = styled.div`
  > ${FooterLink} {
    display: block;
    margin-bottom: 0.75rem;
  }
`;

const Subscribe = styled.div`
  ${Text} {
    margin-bottom: 1rem;
  }
`;

const HrWrapper = styled.div`
  ${pageMargins};
  hr {
    margin: 0;
  }
`;

const Netlify = styled.img``;
const Chromatic = styled.img``;
const Teamcity = styled.img``;
const CircleCI = styled.img``;

const Service = styled.div`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  ${Text} {
    margin-bottom: 0.5rem;
    color: ${color.mediumdark};
  }
`;

const Services = styled.div`
  ${pageMargins};
  padding-top: 2rem;
  padding-bottom: 1rem;

  display: flex;
  flex-wrap: wrap;

  @media (min-width: ${breakpoint}px) {
    justify-content: space-around;
    text-align: center;
  }

  ${Service} {
    flex: 0 0 50%;
    @media (min-width: ${breakpoint}px) {
      flex: 1;
    }
  }

  a {
    display: inline-block;
    transition: all 150ms ease-out;
    transform: translate3d(0,0,0);

    &:hover {
      transform: translate3d(0,-2px,0);
    }

    &:active {
      transform: translate3d(0,0,0);
    }
  }

  ${Netlify}, ${Chromatic}, ${Teamcity}, ${CircleCI} {
    height: 22px;
    width: auto;
    display: inline-block;
    filter: grayscale(100%);
    transition: all 150ms ease-out;

    &:hover {
      filter: grayscale(0%);
    }
  }

  ${Teamcity} {
    /* Makes visual impact the same as other logos */
    padding: 2px 0;
    margin-right: 10px;
  }

  ${Teamcity}, ${CircleCI} {
    /* Turn down the pure black of these logos */
    opacity: .75;
  }
`;

const Lower = styled.div`
  ${pageMargins};
  padding-top: 3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${Colophon} {
    width: 100%;
    margin-bottom: 3rem;
    display: block;

    @media (min-width: ${breakpoint * 1}px) {
      margin-bottom: 3rem;
      width: auto;
      max-width: 200px;
    }
  }

  ${Column} {
    width: 50%;
    margin-bottom: 2.25rem;

    @media (min-width: ${breakpoint}px) {
      padding-right: 20px;
      width: auto;
      margin-bottom: 2.25rem;
    }
  }

  ${Subscribe} {
    width: 100%;
    margin-bottom: 3rem;
    @media (min-width: ${breakpoint}px) {
      width: auto;
      margin-bottom: 3rem;
    }
  }
`;

const FooterWrapper = styled.div`
  background-color: #f4f6f8;
  border-top: 1px solid ${color.border};
  font-size: ${typography.size.s2}px;
  line-height: 20px;
`;

const CountryFlag = styled.span`
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.2.1/flags/4x3/${props => props.country}.svg);
  background-position: 50% center;
  background-repeat: no-repeat;
  background-size: contain;
  box-sizing: border-box;
  display: inline-block;
  font-family: Roboto, sans-serif;
  font-size: 1em;
  height: 1.3em;
  line-height: 1em;
  position: relative;
  vertical-align: middle;
  width: 1.73em;
`;

export default function Footer({
  countries,
  links,
  iubenda,
  hasSubscribed,
  onSubscribe,
  ...props
}) {
  return (
    <FooterWrapper {...props}>
      <Lower>
        <Colophon>
          <LogotypeWrapper isGatsby to={countries.country[0].root_path}>
            <img
              src="https://public.rocketon.app/landings/images/rocketon-logo.svg"
              alt="RocketOn"
            />
          </LogotypeWrapper>
          <Text style={{ fontSize: '12px' }}>
            <span dangerouslySetInnerHTML={{ __html: countries.country[0].copyright }} />
          </Text>
        </Colophon>
        <Column>
          <Title>Links</Title>
          {links.site_links.map(({ link_text, link_url }) => (
            <FooterLink
              tertiary={1}
              key={link_text}
              href={link_url.url}
              target={link_url.target}
              // to={isGatsby ? href : undefined}
              isGatsby={false}
            >
              {link_text}
            </FooterLink>
          ))}
        </Column>
        <Column>
          <Title>{iubenda.section_title}</Title>
          <FooterLink
            tertiary
            className="iubenda-black no-brand iubenda-embed"
            href={`https://www.iubenda.com/privacy-policy/${iubenda.policy_id}`}
          >
            {iubenda.privacy_policy}
          </FooterLink>
          <br />
          <FooterLink
            tertiary
            className="iubenda-black no-brand iubenda-embed"
            href={`https://www.iubenda.com/privacy-policy/${iubenda.policy_id}/cookie-policy`}
          >
            {iubenda.cookie_policy}
          </FooterLink>
          {/* <FooterLink tertiary href={url.twitter}>
            A empresa
          </FooterLink> */}
        </Column>
        <Column>
          <Title>{countries.section_title}</Title>
          {countries.country.map(country => (
            <FooterLink
              tertiary={1}
              key={`RocketOn ${country.name} (${country.language})`}
              to={country.root_path}
              isGatsby
            >
              <CountryFlag country={country.code} />
              {` `}
              {`RocketOn ${country.name} (${country.language})`}
            </FooterLink>
          ))}
        </Column>
      </Lower>
    </FooterWrapper>
  );
}
