import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color } from './shared/styles';
import icons from './shared/icons';

const Svg = styled.svg`
  display: inline-block;
  vertical-align: middle;
`;

const Path = styled.path`
  /* fill: ${color.darkest}; */
  fill: #fdfdfd !important
`;

function Icon({ icon, ...props }) {
  return (
    <Svg viewBox="0 0 1024 1024" width="25px" height="25px" {...props}>
      <Path d={icons[icon]} />
    </Svg>
  );
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
};

export default Icon;
