/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import IndexScreen from '../components/screens/IndexScreen/IndexScreen';
import { GlobalStyle } from '../components/basics/shared/global';
import { site } from '../components/basics';

const { i18n } = site;

export const LocaleContext = React.createContext();

// In theory we could pass in props that we'd fetched via Gatsby's GraphQL
const Index = ({
  data: { header, countries, links, iubenda, pageIndex },
  pageContext,
  location,
}) => (
  <Fragment>
    <GlobalStyle />
    <LocaleContext.Provider value={{ locale: pageContext.locale, i18n }}>
      <IndexScreen
        header={header.data}
        countries={countries.data}
        links={links.data}
        iubenda={iubenda.data}
        pageIndex={pageIndex.data}
        pageContext={pageContext}
        locale={pageContext.locale}
        pathname={location.pathname}
      />
    </LocaleContext.Provider>
  </Fragment>
);

export default Index;

Index.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    header: PropTypes.object.isRequired,
    pageIndex: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query IndexQuery($locale: String!) {
    header: prismicHeader(lang: { eq: $locale }) {
      data {
        items {
          text
          url {
            link_type
            url
            target
          }
        }
      }
    }
    countries: prismicCountries(lang: { eq: $locale }) {
      data {
        section_title
        country {
          root_path
          name
          code
          locale
          short_locale
          language
          copyright
        }
      }
    }
    links: prismicLinks(lang: { eq: $locale }) {
      data {
        site_links {
          link_text
          link_url {
            link_type
            url
            target
          }
        }
      }
    }
    iubenda: prismicIubenda(lang: { eq: $locale }) {
      data {
        section_title
        language
        site_id
        policy_id
        cookie_policy
        privacy_policy
      }
    }
    pageIndex: prismicPageIndex(lang: { eq: $locale }) {
      data {
        hero_title
        hero_subtitle
        hero_background {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        sign_up_button_text
        sign_up_button_link
        sign_in_button_text
        sign_in_button_link
        hero_tagline
        bottom_action_text
        bottom_action_button_text
        bottom_action_button_link
        title
        description
        og_title
        og_description
        og_image
        og_site_name
        body {
          slice_type
          id
          primary {
            feature_title
            feature_subtitle
            video_position
            soon_tag
            visible
            feature_video {
              url
            }
          }
          items {
            sub_feature_title
            sub_feature_subtitle
            soon_tag
            sub_feature_img {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 480) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
